/**
 * FAQ page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Blocksupcategoryfaq from "../components/blocksupcategoryfaq"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import "../styles/pages/faqs.scss"

/* Page function declaration */
const FaqPage = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageFaqs

  //CTA Footer page LEARN
  var ctaFooterLearn= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "learn"
  })

  return (
    <Layout className="faq">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        datastructured="faqs"
      />

      {/* Section Hero */}
      <Hero colorShape="blue" colorHero="white" classNamePage="faq" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralFaq} contentH1={dataAcf.contentGeneralFaq} classNameH1="faq white" vc="true" />

      {/* Main content */}
      <section className="section-faq main-section blue">
        <div className="content-general">
          <Blocksupcategoryfaq
            titleSupCat={dataAcf.titleCategory}
            dataPC1={data.typesFaqs}
            dataPC2={data.nameTypesFaqs}
            dataVC1={data.typesFaqsVC}
            dataVC2={data.nameTypesFaqsVC}
          />
        </div>
      </section>

     {/* Section CtaFooter */}
     {ctaFooterLearn.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="learn" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="blue" />
      })}
    </Layout>
  )
}
/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "faq"}) {
      acfPageFaqs {
        titleGeneralFaq
        contentGeneralFaq
        titleCategory
      }
      seo {
        metaDesc
        title
      }
    }
    nameTypesFaqs: allWpFaq {
      distinct(field: terms___nodes___name)
    }
    typesFaqs: allWpFaq {
      nodes {
        types {
          nodes {
            name
            databaseId
          }
        }
      }
    }
    nameTypesFaqsVC: allWpFaqvc {
      distinct(field: terms___nodes___name)
    }
    typesFaqsVC: allWpFaqvc {
      nodes {
        typesvc {
          nodes {
            databaseId
            name
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
/* Export page informations */
export default FaqPage
