/**
 * Const with a param and useStaticQuery element
 * Block Faq by Category component
 * Display for the category the block of a question with his answer in accordion
 */
/* Import section */
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Blockfaq from "./blockfaq"

/* Declaration function */
const Blockfaqbycategory = ({ supcatfaq, idcategoryfaq }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allWpFaq (sort: {fields: menuOrder, order: DESC}, filter: {acfPostFaq: {topThree: {ne: "toppc"}}}) {
          nodes {
            acfPostFaq {
              response
            }
            title
            types {
              nodes {
                name
                databaseId
              }
            }
          }
        }
        allWpFaqvc (sort: {fields: menuOrder, order: DESC}, filter: {acfPostFaqVC: {top3Vc: {ne: "top3vc"}}}) {
          nodes {
            acfPostFaqVC {
              responseVc
            }
            title
            typesvc {
              nodes {
                databaseId
                name
              }
            }
          }
        }
      }
    `
  )

  //Filter the faq by category
  var qrFaqOfCat
  if(supcatfaq === "pc") {
    qrFaqOfCat = data.allWpFaq.nodes.filter(function (faqOfCat) {
      return faqOfCat.types.nodes[0].databaseId === idcategoryfaq
    })
  }else{
    qrFaqOfCat = data.allWpFaqvc.nodes.filter(function (faqOfCat) {
      return faqOfCat.typesvc.nodes[0].databaseId === idcategoryfaq
    })
  }

  //Title of the category
  let titleCat
  qrFaqOfCat.map( (qrfaq, i) => {
    if(i === 0) {
      if(supcatfaq === "pc") {
        titleCat = <h2 className="title-level-2" dangerouslySetInnerHTML={{ __html:qrfaq.types.nodes[0].name}} />
      } else {
        titleCat = <h2 className="title-level-2" dangerouslySetInnerHTML={{ __html:qrfaq.typesvc.nodes[0].name}} />
      }
    }
    return titleCat
  })

  return (
    <div key={idcategoryfaq} className="block-faqbycategory">
      {titleCat}
      {qrFaqOfCat.map(function (qrfaq, i) {
        if(supcatfaq === "pc") {
          return <Blockfaq key={idcategoryfaq+'-'+i} questionFaq={qrfaq.title} responseFaq={qrfaq.acfPostFaq.response} />
        } else {
          return <Blockfaq key={idcategoryfaq+'-'+i} questionFaq={qrfaq.title} responseFaq={qrfaq.acfPostFaqVC.responseVc} />
        }
      })}
    </div>
  )
}

/* Export function */
export default Blockfaqbycategory
