/**
 * Const with a param and useStaticQuery element
 * Block Top Faq by Sup Category component
 * Display for the sup category the Top 3 of block of a question with his answer in accordion
 */
/* Import section */
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Blockfaq from "./blockfaq"

/* Declaration function */
const Blocktopfaqbysupcategory = ({ supcatfaq }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allWpFaq (sort: {fields: menuOrder, order: DESC}) {
          nodes {
            acfPostFaq {
              response
              topThree
            }
            title
          }
        }
        allWpFaqvc (sort: {fields: menuOrder, order: ASC}) {
          nodes {
            acfPostFaqVC {
              responseVc
              top3Vc
            }
            title
          }
        }
      }
    `
  )

  //Filter the faq by top 3
  var qrFaqOfTop
  if(supcatfaq === "pc") {
    qrFaqOfTop = data.allWpFaq.nodes.filter(function (faqOfTop) {
      return faqOfTop.acfPostFaq.topThree !== null
    })
  }else{
    qrFaqOfTop = data.allWpFaqvc.nodes.filter(function (faqOfTop) {
      return faqOfTop.acfPostFaqVC.top3Vc !== null
    })
  }

  let titleTop
  if(supcatfaq === "pc") {
    titleTop = <div className="title-top-pc"><p className="title-level-2">PortalConnect</p></div>
  }else{
    titleTop = <div className="title-top-vc"><StaticImage  src="../images/virtual_care_blue_icon.png" alt="Icon leaf blue Virtual Care PortalConnect" layout="fixed" width={36} /><p className="title-level-2">Virtual Care</p></div>
  }


  return (
    <div className="block-topfaqbysupcategory">
      {titleTop}
      {qrFaqOfTop.map(function (qrfaq, i) {
        if(supcatfaq === "pc") {
          return <Blockfaq key={'top3pc-'+i} questionFaq={qrfaq.title} responseFaq={qrfaq.acfPostFaq.response} />
        } else {
          return <Blockfaq key={'top3vc-'+i} questionFaq={qrfaq.title} responseFaq={qrfaq.acfPostFaqVC.responseVc} />
        }
      })}
    </div>
  )
}

/* Export function */
export default Blocktopfaqbysupcategory
