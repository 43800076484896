/**
 * Class element
 * Block of Category FAQ and FAQ component
 * Display a nav of the category and display the FAQ of the active category
 */
/* Import section */
import React, { Component }  from "react"
import Blockfaqbycategory from "./blockfaqbycategory"
import "../styles/components/blockcategoryfaq.scss"

/* Declaration class */
class Blockcategoryfaq extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeCategory: "Account &amp; Membership Plan"
    }
  }

  activeCategory(selectCat){
    this.setState({ activeCategory: selectCat })
  }

  render () {
    var categoryFaqs = this.props.catFaq
    var nameCategoryFaqs = this.props.nameCatFaq
    var supCatFaqs = this.props.supCat

    let arrTypes = []
    if(supCatFaqs === "pc") {
      categoryFaqs.nodes.map((catFaq) => (
        arrTypes.push([catFaq.types.nodes[0].databaseId, catFaq.types.nodes[0].name])
      ))
    }else{
      categoryFaqs.nodes.map((catFaq) => (
        arrTypes.push([catFaq.typesvc.nodes[0].databaseId, catFaq.typesvc.nodes[0].name])
      ))
    }

    const arrUniqueValueTypes = getCountIdenticValueofMultipleArr(arrTypes)
    var selectedCategory = this.state.activeCategory
    var idSelectedCategory = (supCatFaqs === "pc") ? 2 : 9

    arrUniqueValueTypes.forEach(value => {
      if(selectedCategory === value[1]){
        idSelectedCategory = value[0]
      }
    })

    return (
      <div className="block-category-faq">
        <p className="title-level-2">You search about...</p>
        <div className="block-button-category flex-content start">
          {nameCategoryFaqs.distinct.map(catdistint => (
            <button key={catdistint} onClick={() => this.activeCategory(catdistint)} aria-label="Open the category" className={catdistint === selectedCategory ? "active-category" : ""} dangerouslySetInnerHTML={{ __html:catdistint}} />
            )
          )}
        </div>
        <div className="separator faq-sep"></div>
        <Blockfaqbycategory key={selectedCategory} supcatfaq={supCatFaqs} idcategoryfaq={idSelectedCategory} />
      </div>
    )
  }
}

/* Export function */
export default Blockcategoryfaq


// Function to get identic value in array multidimensionnal
function getCountIdenticValueofMultipleArr(arr) {
  var a= [], prev;

  arr.sort();
  for ( var i = 0; i < arr.length; i++ ) {
    if ( arr[i][0] !== prev ) {
      a.push(arr[i]);
    }
    prev = arr[i][0];
  }

  return a;
}


