/**
 * Class element
 * Block of Sup Category FAQ with the associate  Blockcategoryfaq
 * Display a nav of the sup category and display the associate  Blockcategoryfaq
 */
/* Import section */
import React, { Component }  from "react"
import Blockcategoryfaq from "./blockcategoryfaq"
import Blocktopfaqbysupcategory from "./blocktopfaqbysupcategory"
import "../styles/components/blocksupcategoryfaq.scss"

/* Declaration class */
class Blocksupcategoryfaq extends Component {

  constructor() {
    super()
    this.state = {
      activeSupCategory: "Virtual Care"
    }
  }

  activeSupCategory(selectSupCat, defaultCatfaq){
    this.setState({
      activeSupCategory: selectSupCat,
      defaultSubCategory: defaultCatfaq
    })
  }

  render () {
    var titleSupCat = this.props.titleSupCat
    var dataPC1 = this.props.dataPC1
    var dataPC2 = this.props.dataPC2
    var dataVC1 = this.props.dataVC1
    var dataVC2 = this.props.dataVC2
    // var supcat = (this.state.activeSupCategory === "Virtual Care") ? "vc" : "pc"
    // var data1 = (this.state.activeSupCategory === "Virtual Care") ? dataVC1 : dataPC1
    // var data2 = (this.state.activeSupCategory === "Virtual Care") ? dataVC2 : dataPC2
    var supcat = "pc"
    var data1 = dataPC1
    var data2 = dataPC2

    return (
      <div className="block-supcategory-faq">
        <p className="title-level-2 center">{titleSupCat}</p>
        <div className="block-button-supcategory">
          {/* <button key="PortalConnect VC" onClick={() => this.activeSupCategory("Virtual Care", "Account & Membership Plan VC")} className={supcat === "vc" ? "active-category" : ""} aria-label="Open the sup category VC">Virtual Care</button> */}
          <button key="PortalConnect" onClick={() => this.activeSupCategory("PortalConnect", "Account & Membership Plan")} className={supcat === "pc" ? "active-category" : ""} aria-label="Open the sup category PC">PortalConnect</button>
        </div>
        <div className="separator faq-sep"></div>
        <Blocktopfaqbysupcategory supcatfaq={supcat} />
        <Blockcategoryfaq key={supcat} supCat={supcat} catFaq={data1} nameCatFaq={data2} />
      </div>

    )
  }
}


/* Export function */
export default Blocksupcategoryfaq



