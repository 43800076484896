/**
 * Class element
 * Block Faq component
 * Display a block of a question with his answer in accordion
 */
/* Import section */
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { FiPlus } from "react-icons/fi"
import "../styles/components/blockfaq.scss"

/* Declaration class */
class Blockfaq extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggleFaq() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    const classOpen = this.state.isOpen ? "open" : ""

    return (
      <div className="block-faq">
        <div className="question-faq" onClick={() => this.toggleFaq()} onKeyDown={() => this.toggleFaq()} role="button" tabIndex={0}>
          <IconContext.Provider value={{ className: "icon-toggle" }}>
            <span className={ classOpen }>
              <FiPlus />
            </span>
          </IconContext.Provider>
          <h3 className={ classOpen }>
            {this.props.questionFaq}
          </h3>
        </div>

        <div className={ `response-faq ${ classOpen }` } dangerouslySetInnerHTML={{ __html:this.props.responseFaq}} />
      </div>
    )
  }
}

/* Export function */
export default Blockfaq
